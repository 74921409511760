<template>
    <nuxt-link
        v-if="card"
        :to="$prismic.linkResolver(card)"
        class="flex"
    >
        <PrismicImage
            w="255"
            h="155"
            :img="card.data.image"
        /> 
    </nuxt-link>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.id];
        }
    }

};
</script>
