<template>
    <div id="info" class="flex boxes">
        <div class="box bg-white">
            <div class="box-inner">
                <CardInfo :card-model="cardModel" />
            </div>
        </div>

        <div class="box bg-lgray">
            <div class="box-inner">
                <h2>{{ $translate('requirement', 'Krav') }}</h2>
                <ul class="list-checkmark text-lighter">
                    <li v-if="cardModel.minAge">{{ minAge }}</li>
                    <li v-if="minimumYearlyIncome">{{ minimumYearlyIncome }}</li>
                    <li v-if="!cardModel.acceptsPaymentRemarks">{{ acceptsPaymentRemarks }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import CardInfo from '@/components/CardInfo.vue';

export default {
    components: {CardInfo},
    props: {
        cardModel: {
            type: Object,
            required: true
        }
    },
    computed: {
        minimumYearlyIncome () {
            const monthlyIncome = this.$format.currency(this.cardModel.minimumYearlyIncome / 12);

            if (this.cardModel.minimumYearlyIncome > 0) {
                return this.$translate('cr_income_of_atleast', 'Inkomst på minst') + ' ' + monthlyIncome + '/' + this.$translate('month', 'mån');
            }
            else if (!this.cardModel.acceptsNoIncome) {
                return this.$translate('employment', 'Anställning');
            } 
            return false;
        },
        acceptsPaymentRemarks () {
            return this.$translate('cr_no_payment_remarks', 'Inga betalningsanmärkningar');
        },
        minAge () {
            return this.$translate('minimum', 'Minst') + ' ' + this.cardModel.minAge + ' ' + this.$translate('year', 'år');
        }
    }
};
</script>

