<template>
    <div class="card-ratings">
        <div v-html="header" />
        <ul class="width-full list-border-bottom">
            <li v-for="rating in ratings" :key="rating.header" class="flex flex-keep padding-y-3">
                <Bar
                    :background="barBackground"
                    :width="rating.score"
                    :hover-text="rating.description"
                >
                    <span class="text-bold">{{ rating.header }}</span>
                </Bar>
            </li>
        </ul>
    </div>
</template>

<script>

import { capitalize } from 'lodash';
export default {
    props: {
        cardModel: {
            type: Object,
            required: true
        },
        barBackground: {
            type: String,
            required: false,
            default: String
        },
        headerTag: {
            type: String,
            required: false,
            default: 'h2'
        }
    },
    computed: {
        header () {
            const text = this.$translate('card_rating_header', 'Hur bra är {card}?')
                .replace('{card}', this.$helpers.escapeHtml(this.cardModel.title));

            return `<${this.headerTag} class="card-ratings-header">${text}</${this.headerTag}>`;
        },
        ratings() {
            const ratings = [
                {
                    score: this.bonusAndBenefitsScore,
                    header: this.$translate('card_rating_bonus', 'Bonus & förmåner'),
                    description: this.$translate('card_rating_bonus_hover', 'Baseras på eventuell bonus, cashback och förmåner som concierge service och loungetillgång.')
                },
                {
                    score: this.insuranceScore,
                    header: this.$translate('card_rating_insurance', 'Försäkringar'),
                    description: this.$translate('card_rating_insurance_hover', 'Baseras på eventuella försäkringar.')
                },
                {
                    score: this.travelScore,
                    header: this.$translate('card_rating_travel', 'På resan'),
                    description: this.travelHoverString
                },
                {
                    score: this.feesScore,
                    header: this.$translate('card_rating_fees', 'Avgifter'),
                    description: this.feesHoverString
                },
                {
                    score: this.approvalRatingScore,
                    header: this.$translate('card_rating_approval', 'Beviljandegrad'),
                    description: this.approvalRatingHoverString
                },
                {
                    score: Math.round(this.cardModel._ratings.average_score * 2),
                    header: this.$translate('card_rating_customer_ratings', 'Kundnöjdhet'),
                    description: this.$translate('card_rating_customer_ratings_hover_text', 'Baserat på kundomdömen.').replace('{count}', this.cardModel._ratings.count)
                }
            ];

            let ratingsCopy = ratings.slice();

            ratingsCopy.sort(function(a, b) {
                return b.score - a.score;
            });

            const overall = ratingsCopy.slice(0, 4).reduce(function(accumulator, rating) {
                return accumulator + rating.score;
            }, 0) / 4;

            ratings.push({
                score: overall,
                header: this.$translate('card_rating_overall', 'Helhetsbetyg'),
                description: this.$translate('card_rating_overall_hover_text', 'Sammanvägt betyg')
            });

            return ratings;
        },
        bonusAndBenefitsScore() {

            let score = 1;
            if (this.cardModel.hasBonusProgram || this.cardModel.hasCashback) {
                score += 5;
            }
            else if (this.cardModel.hasFuelOffers) {
                score += 2.5;
            }

            if (this.cardModel.hasConciergeService) {
                score += 2.5;
            }

            if (this.cardModel.hasLoungeAccess) {
                score += 2.5;
            }

            return score > 10 ? 10 : score;

        },
        insuranceScore() {
            let score = 1;
            if (this.cardModel.hasTravelInsurance) {
                score += 5;
            }

            const otherInsurances = [
                'hasPriceGurantee',
                'hasCancellationProtection',
                'hasDeductibleInsurance',
                'hasComprehensiveInsurance',
                'hasPurchaseProtection',
                'hasDeliveryInsurance',
                'hasExtendedWarranty',
                'hasHoleInOneInsurance',
                'hasLostKeyInsurance',
                'hasWrongFuelInsurance'
            ];

            otherInsurances.forEach(insuranceKey => {
                if (this.cardModel[insuranceKey]) {
                    score++;
                }
            });

            return score > 10 ? 10 : score;
        },
        travelScore() {
            // all credit cards are good when travelling for
            // unexpected costs
            let score = 1;

            if (this.cardModel.hasTravelInsurance) {
                score += 4;
            }

            if (this.cardModel.currencyExchangeType === 'free') {
                score += 3;
            }

            ['hasConciergeService', 'hasLoungeAccess', 'hasComprehensiveInsurance'].forEach(insuranceKey => {
                if (this.cardModel[insuranceKey]) {
                    score += 2;
                }
            });

            return score > 10 ? 10 : score;
        },
        travelHoverString() {

            const templateFeatures = this.$translate('phrase_features_is_included', '{features} ingår.');
            const templateCurrencyExchangeFee = this.$translate('phrase_the_currency_exchange_fee_is', 'Valutapåslaget är {currency-exchange-fee}.');

            let string = templateCurrencyExchangeFee.replace('{currency-exchange-fee}', this.cardModel.getCurrencyExchangeFeeString());
            let features = [];
            if (this.cardModel.hasTravelInsurance) {
                features.push(this.$translate('insurance_travel', 'reseförsäkring'));
            }
            if (this.cardModel.hasComprehensiveInsurance) {
                features.push(this.$translate('insurance_comprehensive', 'sjävlriskreducering'));
            }
            if (this.cardModel.hasLoungeAccess) {
                features.push(this.$translate('benefit_lounge_access', 'loungetillgång'));
            }
            if (this.cardModel.hasConciergeService) {
                features.push(this.$translate('benefit_concierge_service', 'concierge service'));
            }

            const featuresString = features.length ? capitalize(templateFeatures.replace('{features}', features.join(', '))) : '';

            return string + ' ' + featuresString;
        },
        feesScore() {
            let score = 1;
            if (this.cardModel.yearlyCost === 0) {
                score += 5;
            }
            else {
                if (this.cardModel.hasFirstYearOffer && this.cardModel.firstYearCost === 0) {
                    score += 2;
                }

                if (this.cardModel.yearlyCost < 300) {
                    score += 2;
                }
            }

            if (this.cardModel.currencyExchangeType === 'free') {
                score += 2.5;
            }
            if (this.cardModel.withdrawalFeetype === 'free') {
                score += 2.5;
            }

            let interest = 0;
            if (this.cardModel.interestType === 'fixed') {
                interest = this.cardModel._data.interest_fixed;
            }
            else {
                const interval = this.cardModel._data.interest_interval_max - this.cardModel._data.interest_interval_min;
                interest = this.cardModel._data.interest_interval_min + (interval * 0.25);
            }

            if (interest < 14) {
                score += 5;
            }
            else if (interest < 15) {
                score += 4;
            }
            else if (interest < 16) {
                score += 3;
            }
            else if (interest < 18) {
                score += 2;
            }
            else if (interest < 20) {
                score += 1;
            }

            return score > 10 ? 10 : score;

        },
        feesHoverString() {
            const template = this.$translate('card_rating_fees_hover', '{yearly-cost} i årsavgift med ränta på {interest} (effektivt {effective-interest}). Aviavgift på {avi-fee} och {rate-free-days} räntefritt.');
            return template
                .replace('{yearly-cost}', this.cardModel.yearlyCostString)
                .replace('{interest}', this.cardModel.interestString)
                .replace('{effective-interest}', this.cardModel.effectiveInterestString)
                .replace('{avi-fee}', this.cardModel.getAviFeeString())
                .replace('{rate-free-days}', this.cardModel.getInterestFreeDaysString(this.$translate('bd_days', '{days} dagar')));
        },
        approvalRatingScore() {
            let conversionRate = this.cardModel._core.conversion_rate;
            conversionRate = conversionRate * 100;
            conversionRate = conversionRate > 5 ? 5 : conversionRate;
            return Math.round(conversionRate * 2);
        },
        approvalRatingHoverString() {
            if (this.cardModel._core.sales < 5) {
                return this.$translate('card_rating_approval_hover_no_data', 'Inte tillräckligt med data.');
            }

            return this.$translate('card_rating_approval_hover', 'Baseras på antalet godkända ansökningar de senaste 30 dagarna genom oss på Alltomkreditkort.se.');
        }
    }
};
</script>
