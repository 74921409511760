<template>
    <Section
        class="info-box"
        width="sm"
        :class="getBackground(data.primary.background)"
    >
        <div class="box-color padding-8 bxs br-3 box border" :class="getBackground(data.primary.box_color)">
            <div 
                class="box-title rich-text" 
                v-html="$prismic.asHtml(data.primary.title)"
            />
            <div 
                class="box-text rich-text" 
                v-html="$prismic.asHtml(data.primary.content)" 
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    methods: {
        getBackground(color) {
            return {
                'white': 'bg-white', 
                'blue': 'bg-blue',
                'green': 'bg-green',
                'darkblue': 'bg-darkblue',
                'lgray': 'bg-lgray',
                'slgray': 'bg-slgray',
            }[color] || 'bg-white';
        }
    }
};
</script>